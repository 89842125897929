import React, { Component } from "react"
import Button from "react-bootstrap/Button"
import styles from './login.module.styl'

export default class Login extends Component {
  render() {
    const { username, password, loginClick, usernameChange, passwordChange, loggingIn } = this.props
    return (
      <div className={styles.loginBox}>
        <h1>Login</h1>
        <form>
          <div className={"form-group"}>
            <label>Username:</label>
            <input
              className={"form-control"}
              placeholder={"Username"}
              value={username || ''}
              onChange={usernameChange} />
          </div>
          <div className={"form-group"}>
            <label>Password:</label>
            <input
              className={"form-control"}
              type={"password"}
              placeholder={"Password"}
              value={password || ''}
              onChange={passwordChange} />
          </div>
          <Button variant={"primary"} onClick={loginClick} disabled={loggingIn}>{loggingIn ? "Logging In..." : "Login"}</Button>
        </form>
      </div>
    )
  }
}