import React, { Component } from 'react'
import Backendless from "backendless"
// import { connect } from 'react-redux'

import Layout from '../components/layout'
import Login from '../components/login'
import ChatModule from '../components/chatModule'

class Chat extends Component {
  constructor(props) {
    super(props)

    const cache = Backendless.LocalCache.getAll()

    let loggedUser = null

    if(cache["stayLoggedIn"]) {
      let tokenExist = Backendless.UserService.isValidLogin()
      if(tokenExist) {
        loggedUser = Backendless.UserService.getCurrentUserSync()
      } else {
        Backendless.LocalCache.clear()
      }
    }

    this.state = {
      loggedIn: loggedUser !== null,
      loggedUser: loggedUser,
      loggingIn: false,
      loginFields: {
        username: "",
        password: "",
      }
    }
  }

  loginSubmit = () => {
    this.setState({loggingIn: true})
    // this.setState({loggedIn: true, loggedUser: this.state.loginFields.username})
  }
  usernameChange = e => this.setState({loginFields: {...this.state.loginFields, username: e.target.value}})
  passwordChange = e => this.setState({loginFields: {...this.state.loginFields, password: e.target.value}})

  render() {
    const { loggedIn, loggedUser, loginFields, loggingIn } = this.state

    return (
      <Layout current={"chat"}>
        <h2>Chat Concept <small className={"text-muted font-weight-lighter"}>Logged in as {loggedIn ? loggedUser : "Not Logged In"}</small></h2>
        {!loggedIn
          ? <Login
              loginClick={this.loginSubmit}
              username={loginFields.username}
              usernameChange={this.usernameChange}
              password={loginFields.password}
              passwordChange={this.passwordChange}
              loggingIn={loggingIn}
            />
          : <ChatModule />}
      </Layout>
    )
  }
}

export default Chat